import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { client } from '@/env';
import { makeInternalUrlRelativeAtHtml } from '@separate/utilities/urlsProcessing';
import styles from './articleBlock.module.scss';

export default function RichTextBlock({ data }) {

  const incomingHtml = data?.rich_text_editor;

  const richText = React.useMemo(() => {
    if (!client) return incomingHtml;

    return makeInternalUrlRelativeAtHtml(incomingHtml);
  }, [incomingHtml]);

  return (
    <div className={`${styles.articleRichText} my-1 mx-auto`} dangerouslySetInnerHTML={{ __html: richText }} />
  );
}

RichTextBlock.propTypes = {
  data: PropTypes.shape({
    rich_text_editor: PropTypes.string.isRequired,
  }),
};
