import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import { useLogLinkClick } from '@separate/hooks/analytics';

const AnalyticsLink = (props) => {
  const { href, className, children, onClick, position, activity, type, step, isExternal, ...extraProps } = props;
  const eventProperties = { href, position, activity, step, type, isExternal: isExternal && true };
  const { logLinkClick } = useLogLinkClick(eventProperties);

  const handleClick = () => {
    onClick?.();
    logLinkClick();
  };

  return (
    <Link href={href}>
      <a className={className} onClick={handleClick} { ...extraProps }>
        {children}
      </a>
    </Link>
  );
};

export default AnalyticsLink;

AnalyticsLink.propTypes = {
  href: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  onClick: PropTypes.func,
  position: PropTypes.string,
  activity: PropTypes.string,
  type: PropTypes.string,
  step: PropTypes.string,
  isExternal: PropTypes.bool,
};
