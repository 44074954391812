import React from 'react';
import PropTypes from 'prop-types';
import { Pagination as BootstrapPagination } from 'react-bootstrap';
import LinkButton from '@/components/LinkButton';
import { usePagination, DOTS } from '@/hooks/usePagination';
import styles from './pagination.module.scss';

export default function Pagination(props) {
  const {
    pagePath,
    pageCount,
    siblingCount,
    currentPage,
    pageSize,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    pageCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  const previousPagePath = () => pagePath(currentPage - 1);
  const nextPagePath = () => pagePath(currentPage + 1);

  return (
    <>
      <BootstrapPagination
        className={`${styles.pagination} justify-content-center align-items-center mt-1`}
      >
        <li>
          <LinkButton
            href={previousPagePath()}
            className={currentPage === 1 ? styles.previousDisabled : styles.previous}
          >
            {currentPage === 1 && <span>‹</span>}
          </LinkButton>
        </li>

        {paginationRange.map((pageNumber, index) => {
          return pageNumber === DOTS ? (
            <BootstrapPagination.Ellipsis
              className={styles.ellipsis}
              disabled
              key={index}
            />
          ) : (
            <li className='d-flex justify-content-center align-items-center' key={index}>
              <LinkButton
                href={pagePath(pageNumber)}
                className={`${styles.paginationItems} ${
                  currentPage === pageNumber ? styles.active : ''
                }`}
              >
                {pageNumber}
              </LinkButton>
            </li>
          );
        })}

        <li>
          <LinkButton
            href={nextPagePath(currentPage + 1)}
            className={currentPage === lastPage ? styles.nextDisabled : styles.next}
          >
            {currentPage === lastPage && <span>›</span>}
          </LinkButton>
        </li>
      </BootstrapPagination>
    </>
  );
}

Pagination.propTypes = {
  pagePath: PropTypes.func,
  pageCount: PropTypes.number,
  siblingCount: PropTypes.number,
  currentPage: PropTypes.number,
  pageSize: PropTypes.number,
};
