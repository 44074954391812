import React from 'react';
import PropTypes from 'prop-types';
import styles from './articleBlock.module.scss';

export default function YoutubeVideoBlock({ data }) {
  const embedUrl = data.url.replace(
    /https:\/\/www\.youtube\.com\/watch\?v=/,
    "https://www.youtube.com/embed/"
  );

  return (
    <div className={`${styles.articleYoutubeVideo} text-center my-2 mx-auto`}>
      <iframe
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
}

YoutubeVideoBlock.propTypes = {
  data: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }),
};
