import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import styles from './articleBlock.module.scss';

export default function PhoneCtaBlock({ data }) {
  return (
    <div className="text-center py-2">
      <Link href={`tel:${data.phone_number}`}>
        <a><Button className={styles.articleCta}>{data.cta_text}</Button></a>
      </Link>
    </div>
  );
}

PhoneCtaBlock.propTypes = {
  data: PropTypes.shape({
    cta_text: PropTypes.string.isRequired,
    phone_number: PropTypes.string.isRequired,
  }),
};
