import uniq from "lodash/uniq";
import * as env from "@/env";

const ACCENTS = {
  a: "á",
  e: "é",
  i: "í",
  o: "ó",
  u: "ú",
  n: "ñ",
  á: "a",
  é: "e",
  í: "i",
  ó: "o",
  ú: "u",
  ñ: "n"
};
const LANG = { es: "es-mx", en: "en-us" };
const SEARCH_MAX_WORDS = 5;

const VOWEL_REGEXP = new RegExp(`[${Object.keys(ACCENTS).join()}]`, "gi");

function escapeRegexp(string) {
  const escaped = string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  return escaped.replace(
    VOWEL_REGEXP,
    l => `[${l.toLowerCase()}${ACCENTS[l.toLowerCase()]}]`
  );
}

function searchQuery(words, ids) {
  const wordQueries = words.map(unscapedWord => {
    const word = escapeRegexp(unscapedWord);
    const query = { $regex: word, $options: "i" };

    return {
      $or: [
        { title: query },
        { "article_content.rich_text.rich_text_editor": query },
        { "article_content.call_to_action.cta_url.title": query },
        { "article_content.block_quote.quote_text": query },
        { "article_content.text.text": query }
      ]
    };
  });

  if (ids) {
    wordQueries.push({ uid: { $in: ids } });
  }

  return { $and: wordQueries };
}

async function contentstackCall(params) {
  const query = new URLSearchParams({
    environment: env.CONTENT_STACK_ENV,
    ...params
  });

  const url = `https://cdn.contentstack.io/v3/content_types/articles/entries/?${query.toString()}`;

  const res = await fetch(url, {
    credentials: "omit",
    mode: "cors",
    headers: {
      api_key: env.CONTENT_STACK_API_KEY,
      access_token: env.CONTENT_STACK_DELIVERY_TOKEN
    }
  });

  return res.json();
}

export async function searchArticles(query, locale) {
  if (!query) {
    return [];
  }

  const words = uniq(query.split(/\s+/g));
  if (words.length > 25) return [];
  if (words.some(w => w.length > 22)) return [];
  if (!words.some(w => w.length >= 2)) return [];

  words.sort((a, b) => b.length - a.length);

  const queryOne = searchQuery(words.slice(0, SEARCH_MAX_WORDS));
  let { entries } = await contentstackCall({
    query: JSON.stringify(queryOne),
    desc: "created_at",
    locale: LANG[locale]
  });
  let offset = SEARCH_MAX_WORDS;
  while (entries.length && offset < words.length) {
    const queryTwo = searchQuery(
      words.slice(offset, offset + SEARCH_MAX_WORDS),
      entries.map(e => e.uid)
    );
    entries = (
      await contentstackCall({
        query: JSON.stringify(queryTwo),
        desc: "created_at",
        locale: LANG[locale]
      })
    ).entries;
    offset += SEARCH_MAX_WORDS;
  }

  return entries;
}
