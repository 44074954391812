import React from 'react';
import Head from 'next/head';
import PropTypes from 'prop-types';
import { hrefType } from '@/types';

const MetaHead = ({ title, description, type, imageUrl, imageType, imageWidth, imageHeight }) => {
  const ogType = type ? type : 'website';

  return (
    <Head>
      <title>{title}</title>
      <meta content={ogType}  property="og:type" />
      {title && description && (
        <>
          <meta content={title} name="title" key="og:title" property="og:title" />
          <meta content={description} name="description" key="og:description" property="og:description" />
          <meta content={description} key="description" property="description" />
        </>
      )}
      {imageUrl && (
        <>
          <meta content={imageUrl} property="og:image" />
          <meta content={imageType} property="og:image:type" />
          <meta content={imageHeight} property="og:image:height" />
          <meta content={imageWidth} property="og:image:width" />
        </>
      )}
    </Head>
  );
};

export default MetaHead;

MetaHead.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  type: PropTypes.string,
  imageUrl: hrefType,
  imageType: PropTypes.string,
  imageWidth: PropTypes.string,
  imageHeight: PropTypes.string,
};
