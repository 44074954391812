import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import { useLogCtaClicked } from '@separate/hooks/analytics';
import styles from './articleBlock.module.scss';

export default function BannerAdBlock({ data }) {
  const { banner_image, banner_text, header_text, url } = data;
  const { logCtaHandleClick } = useLogCtaClicked();

  return (
    <div
      className={cx(styles.bannerAd)}
      style={{ backgroundImage: `url(${banner_image?.url})` }}
    >
      <h3>{header_text}</h3>
      <p>{banner_text}</p>
      <LinkButton
        href={url.href}
        className={styles.linkButton}
        analyticsProperties={logCtaHandleClick(url.href, false)}
      >
        {url.title}
      </LinkButton>
    </div>
  );
}

BannerAdBlock.propTypes = {
  data: PropTypes.object,
};
