import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import LinkButton from '@/components/LinkButton';
import { useTranslation } from '@separate/hooks/translation';
import { Faq, Health, Education, Finance, Legal} from './../../icons/article';
import styles from './article.module.scss';

// Excludes 'search', which is not an actual content category
export const filterCategories = [
  { category: "finance", icon: Finance },
  { category: "health", icon: Health },
  { category: "education", icon:  Education},
  { category: "legal", icon: Legal },
];

/**
 * A bar of button links to filter categories
 */
export function FilterBar({ selected }) {
  const { t } = useTranslation();

  function handleLink(category) {
    if (category === selected) {
      return '/articles';
    } else {
      return `/articles/${category}`;
    }
  }

  return (
    <div className={`${styles.filterBar} d-flex justify-content-start`}>
      {filterCategories.map(({ category, icon: Icon }) => (
        <div key={category}>
          <LinkButton
            href={handleLink(category)}
            className={cx("me-2", { [styles.selected]: category === selected, [styles.unselected]: !selected })}
          >
            <Icon className={styles.iconButton} /> {t(`articles.categories.${category}`)}
          </LinkButton>
        </div>
      ))}
    </div>
  );
}

FilterBar.propTypes = {
  selected: PropTypes.oneOf([...filterCategories.map(({ category }) => category), 'search']),
};
