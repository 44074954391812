import React from 'react';
import { useRouter } from 'next/router';
import { useCurrentUser } from '@separate/hooks/user';
import { isFeatureEnabled } from '@separate/utilities/features';

export const useConfirmationRedirect = () => {
  const { user, isLoggedIn } = useCurrentUser();
  const isPremiumMember = user?.is_premium_member;
  const hasConfirmedPhone = user?.phone_confirmed;
  const { isReady, replace } = useRouter();
  const prevent2faForNewUsers = isFeatureEnabled('prevent_2fa_for_new_users');

  React.useEffect(() => {
    if(!prevent2faForNewUsers || !isReady) return;

    if (isLoggedIn && isPremiumMember && !hasConfirmedPhone) {
      replace('/premium/enrollment');
    }
  }, [prevent2faForNewUsers, isReady, isPremiumMember, hasConfirmedPhone, isLoggedIn, replace]);
};
