import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import PodercardCard from '@/components/cards/PodercardCard';
import styles from './articleBlock.module.scss';

export default function AdUnitBlock({ data }) {
  return (
    <>
      {data.show_ad && (
        <div className={cx(styles.adUnit, "my-3 mx-auto")}>
          <PodercardCard />
        </div>
      )}
    </>
  );
}

AdUnitBlock.propTypes = {
  data: PropTypes.shape({
    show_ad: PropTypes.bool,
  }),
};
