import React from 'react';
import PropTypes from 'prop-types';
import { optimizedSrcset } from '@separate/utilities/contentstack';

const ContentstackImage = ({ src, alt, className, width, height }) => {
  const imageAttributes = optimizedSrcset(src, { default: [width, height] });

  return (
    <img alt={alt} className={className} {...imageAttributes} />
  );
};

export default ContentstackImage;

ContentstackImage.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  width: PropTypes.number.isRequired,
  height: PropTypes.number,
};
