import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import styles from './articleBlock.module.scss';

export default function BlockQuoteBlock({ data }) {
  return (
    <blockquote className={cx(styles.blockQuote, 'blockquote mx-auto')} dangerouslySetInnerHTML={{ __html: data.quote_text }} />
  );
}

BlockQuoteBlock.propTypes = {
  data: PropTypes.shape({
    quote_text: PropTypes.string.isRequired,
  }),
};
