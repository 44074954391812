import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ContentstackImage from '@/components/ContentstackImage';
import styles from './articleBlock.module.scss';

export default function VerticalImageBlock({ data }) {
  return (
    <div className={cx(styles.imageWrapper, "text-center my-2 mx-auto")}>
      <ContentstackImage
        width={518}
        height={0}
        src={data.image.url}
        alt={data.image.alt || 'Article Image'}
        className={`${styles.articleImage} `}
      />
    </div>
  );
}

VerticalImageBlock.propTypes = {
  data: PropTypes.shape({
    image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  }),
};
