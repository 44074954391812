import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';
import ArticleCard from '@/components/cards/ArticleCard';
import ArticleCardDesktop from '@/components/cards/ArticleCardDesktop';
import { useCurrentUser } from '@separate/hooks/user';
import styles from './article.module.scss';

export function ArticleFeed({ articles, searchResultsCount, asGrid }) {
  const { user, isLoggedIn, isLoading } = useCurrentUser();
  const contentWrapper = React.useRef(null);
  const offset = 230;

  const sideScroll = (distance) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      contentWrapper.current.scrollLeft += distance;
      contentWrapper.current;
      scrollAmount += Math.abs(distance);
      if (scrollAmount >= Math.abs(distance)) {
        clearInterval(slideTimer);
      }
    }, 25);
  };

  const showSidesScrolling = articles?.length >= 2 || searchResultsCount >= 2;

  return (
    <>
      {asGrid ? (
        <div className={cx(searchResultsCount ? styles.articleFeedDesktopWithSearch : styles.articleFeedDesktop, 'mt-4')}>
          {articles?.map((article, index) => (
            <ArticleCardDesktop
              article={article}
              key={article.uid}
              className={styles[`article-${index + 1}`]}
              index={index}
              searchResultsCount={searchResultsCount}
              userIsPremium={user?.is_premium_member}
              isLoading={isLoading}
              isLoggedIn={isLoggedIn}
              user={user}
            />
          ))}
        </div>
      ) : (
        <div className={styles.articleFeed}>
          <div className={styles.carousel} ref={contentWrapper}>
            {/* margins and width must be controlled by the outside container like "Row and Col" */}
            {articles?.map((article) => (
              <div key={article.uid} className="p-2 mx-2" style={{width: `${offset}px`}}>
                <ArticleCard
                  article={article}
                  userIsPremium={user?.is_premium_member}
                  isLoggedIn={isLoggedIn}
                  isLoading={isLoading}
                  user={user}
                />
              </div>
            ))}
          </div>

          {showSidesScrolling && (
            <>
              <button onClick={() => {sideScroll(-offset);}} className={styles.slideLeft} />
              <button onClick={() => {sideScroll(offset);}} className={styles.slideRight} />
            </>
          )}
        </div>
      )}
    </>
  );
}

ArticleFeed.propTypes = {
  articles: PropTypes.array.isRequired,
  searchResultsCount: PropTypes.number,
  asGrid: PropTypes.bool,
};
