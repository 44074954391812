import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import ContentstackImage from '@/components/ContentstackImage';
import styles from './articleBlock.module.scss';

export default function ImageBlock({ data }) {
  return (
    <div className={cx(styles.imageWrapper, 'mx-auto')}>
      <ContentstackImage
        width={500}
        height={0}
        src={data.in_article_image.url}
        alt={data.in_article_image.alt || 'Article Image'}
        className={styles.articleImage}
      />
    </div>
  );
}

ImageBlock.propTypes = {
  data: PropTypes.shape({
    in_article_image: PropTypes.shape({
      url: PropTypes.string.isRequired,
      alt: PropTypes.string,
    }),
  }),
};
