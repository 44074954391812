import React from 'react';
import cx from "classnames";
import PropTypes from 'prop-types';
import styles from './articleBlock.module.scss';

export default function TextBlock({ data }) {
  return (
    <div className={cx(styles.articleText, "mx-auto")}>
      <p dangerouslySetInnerHTML={{ __html: data.text }} />
    </div>
  );
}

TextBlock.propTypes = {
  data: PropTypes.shape({
    text: PropTypes.string.isRequired,
  }),
};
