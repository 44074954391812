import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Spinner from "react-bootstrap/Spinner";
import AnalyticsLink from '@/components/AnalyticsLink';
import { logCtaClicked } from '@separate/utilities/analytics';
import styles from './link-button.module.scss';

export default function LinkButton({
  activity,
  analyticsProperties,
  children,
  className,
  disabled,
  href,
  isExternal,
  loading,
  onClick,
  position,
  step,
  type,
  ...extraProps
}) {
  function handleClick() {
    if (analyticsProperties) {
      logCtaClicked(analyticsProperties);
    }

    onClick?.();
  }

  return (
    <AnalyticsLink
      href={href}
      className={cx(className, styles.link, 'btn btn-primary')}
      onClick={handleClick}
      disabled={loading || disabled}
      role="button"
      position={position}
      activity={activity}
      type={type}
      step={step}
      isExternal
      { ...extraProps }
    >
      {loading ? (
        <Spinner animation="border" size="sm" className={styles.spinner} />
      ) : (
        <>{children}</>
      )}
    </AnalyticsLink>
  );
}

LinkButton.propTypes = {
  activity: PropTypes.string,
  analyticsProperties: PropTypes.object,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string.isRequired,
  isExternal: PropTypes.bool,
  loading: PropTypes.bool,
  onClick: PropTypes.func,
  position: PropTypes.string,
  step: PropTypes.string,
  type: PropTypes.string,
};
