import * as React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import styles from '../standardCard.module.scss';

export default function StandardCard(props) {
  const { className, backgroundColor, backgroundImage, children, bodyClassName, ...extraProps } = props;

  function background() {
    if(backgroundImage) {
      return { backgroundImage: `url(${backgroundImage})` };
    }
    return { backgroundColor };
  }

  return (
    <Card
      className={cx(styles.standard, styles.card, className)}
      style={background()}
      {...extraProps}
    >
      <Card.Body className={bodyClassName}>
        {children}
      </Card.Body>
    </Card>
  );
}

StandardCard.propTypes = {
  className: PropTypes.string,
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  bodyClassName: PropTypes.string,
  children: PropTypes.node,
};
