import * as React from 'react';
import cx from 'classnames';
import Link from 'next/link';
import PropTypes from 'prop-types';
import Card from 'react-bootstrap/Card';
import Button from '@/components/Button';
import ContentstackImage from '@/components/ContentstackImage';
import { Lock } from '@/icons';
import { articleType } from '@/types';
import { articleImage } from '@/utilities/articles/articleCard';
import { useTranslation } from "@separate/hooks/translation";
import { setAnalyticsUser } from "@separate/utilities/analytics";
import { formatLocalDateSimple } from '@separate/utilities/datetime';
import { standard } from '../standardCard.module.scss';
import Actions from './Actions.jsx';
import styles from './articleCard.module.scss';

export default function ArticleCard(props) {
  const { article, className, userIsPremium, isLoggedIn, isLoading, user, ...extraProps } = props;
  const { t } = useTranslation();
  const [showMessage, setShowMessage] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);
  const isLocked = !userIsPremium && article.is_premium;
  const linkToArticle = isLocked ? '#/' : `/articles/${article.article_category}${article.url}`;

  function handleCardClick() {
    setAnalyticsUser(user);

    if (!isLocked) {
      return;
    }
    setShowMessage(true);
  }

  function handleLogin() {
    setShowMessage(false);
    setShowLogin(true);
  }

  const premiumUserAndArticle = userIsPremium && article.is_premium;
  const loadingUserAndArticleIsPremium = isLoading && article.is_premium;

  return (
    <>
      <Link href={linkToArticle}>
        <a className={cx(styles.link, loadingUserAndArticleIsPremium && styles.inactiveLink)}>
          <Card
            className={cx(standard, styles.card, className)}
            onClick={handleCardClick}
            {...extraProps}
          >
            <>
              <ContentstackImage
                className={styles.backgroundImage}
                src={articleImage(article)}
                width={230}
                height={130}
              />
              <Card.Body className={styles.cardBody}>
                <div className={styles.content}>
                  <p className={cx(styles.info, "mb-2")}>
                    {t("articles.published")} {formatLocalDateSimple(article?.publish_details?.time)}
                  </p>

                  <h4 className={cx(styles.title, "mb-1")}>{article.title}</h4>

                  <Button className={`${styles.button} text-capitalize`}>
                    {t(`articles.categories.${article.article_category}`)}
                  </Button>
                </div>
              </Card.Body>
              {loadingUserAndArticleIsPremium || isLocked ? (
                <div className={cx(styles.lock, "d-flex flex-column justify-content-center align-items-center")}>
                  <Lock className="mb-1" />
                  SEP+
                </div>
              ) : premiumUserAndArticle && (
                <div className={cx(styles.premiumUserAndArticle, "d-flex flex-column justify-content-center align-items-center")}>
                  SEP+
                </div>
              )}
            </>
          </Card>
        </a>
      </Link>
      {isLocked && (
        <Actions
          showMessage={showMessage}
          showLogin={showLogin}
          dismissMessage={() => setShowMessage(false)}
          dismissLogin={() => setShowLogin(false)}
          handleLogin={handleLogin}
          isLoggedIn={isLoggedIn}
        />
      )}
    </>
  );
}

ArticleCard.propTypes = {
  article: articleType,
  className: PropTypes.string,
  userIsPremium: PropTypes.bool,
  isLoggedIn: PropTypes.bool,
  isLoading: PropTypes.bool,
  user: PropTypes.object,
};
