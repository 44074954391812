import React from 'react';

export default function useWindowSize() {
  const [isOnDesktop, setIsOnDesktop] = React.useState(true);
  React.useEffect(() => {
    function handleResize() {
      if (window.innerWidth < 768) {
        setIsOnDesktop(false);
      } else {
        setIsOnDesktop(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return isOnDesktop;
}
